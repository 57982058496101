const eventsStyles = {
  eventContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: 5,
    pb: 5,
    pt: 4,
  },
  eventWrapper: {
    backgroundColor: "coverBackground",
  },
  eventContent: {
    overflow: "hidden",
    variant: "variants.container",
    m: "0 auto",
    pt: [3, 4, 4, 5],
    px: [3, 4, 4, 5],
  },
  eventLink: {
    color: "#2d54c9",
  },
  eventheading: {
    fontWeight: 900,
    fontSize: 5,
    mb: 0,
  },
  eventparagraph: {
    mt: 0,
    mb: 0,
    color: "rgba(0,0,0,0.8)",
  },
  eventCard: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr"],
    gridGap: 0,
    backgroundColor: "#fff",
    overflow: "hidden",
    alignItems: "center",
    borderRadius: "12px",
  },

  eventBox: {
    backgroundColor: "#fff",
  },
  eventBoxDiv: {
    img: {
      display: "block",
      maxWidth: "100%",
      width: "100%",
    },
  },
  eventMatter: {
    gridGap: [3],
    mt: 4,
  },
  boxInfo: {
    display: "grid",
    alignItems: "center",
    px: 4,
    py: 4,
  },
  boxSection: {
    alignItems: "center",
  },
  heading: {
    fontWeight: 600,
    fontSize: 4,
    lineHeight: "140%",
  },
  description: {
    fontSize: 1,
    color: "#565656",
  },
  eventinfo: {
    fontWeight: "600",
    fontSize: 0,
    color: "#A3A3A3",
  },
  eventdetails: {
    fontWeight: "600",
    fontSize: 0,
    pt: 1,
    color: "#1C2F26",
    display: "flex",
    alignItems: "center",
    ".icon": {
      pr: 2,
      opacity: "0.5",
    },
  },
  button: {
    mt: 4,
    fontSize: 3,
    p: "16px 24px",
  },
}
export default eventsStyles
