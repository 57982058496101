/** @jsx jsx */
import { jsx, Card, Grid, Box } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { RiArrowRightLine } from "react-icons/ri"
import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../assets/scss/blog-post.scss"
import LinkButton from "../components/link-button"
import eventsStyles from "../components/events.styles"

export const eventsQuery = graphql`
  query eventsQuery {
    allStrapiEvents(sort: { order: ASC, fields: eventDate }) {
      edges {
        node {
          id
          date
          description
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
          time
          location
          url
        }
      }
    }
  }
`

const EventCard = props => {
  return (
    <Card sx={eventsStyles.eventCard}>
      <Box sx={eventsStyles.eventBox}>
        <div>
          <GatsbyImage
            image={props.image.childImageSharp.gatsbyImageData}
            sx={eventsStyles.eventBoxDiv}
          />
        </div>
      </Box>
      <Box sx={eventsStyles.boxInfo}>
        <section sx={eventsStyles.boxSection}>
          <Link to={props.url} sx={eventsStyles.eventLink}>
            <h1 sx={eventsStyles.heading}>{props.title}</h1>
          </Link>
          <p sx={eventsStyles.description}>{props.description}</p>

          <Grid columns={[1, 2, 3]} sx={eventsStyles.eventMatter}>
            <div>
              <div sx={eventsStyles.eventinfo}>DATE</div>
              <div sx={eventsStyles.eventdetails}>{props.date}</div>
            </div>
            <div>
              <div sx={eventsStyles.eventinfo}>TIME</div>
              <div sx={eventsStyles.eventdetails}>{props.time}</div>
            </div>
            <div>
              <div sx={eventsStyles.eventinfo}>LOCATION</div>
              <div sx={eventsStyles.eventdetails}>{props.location}</div>
            </div>
          </Grid>

          <LinkButton
            to={props.url}
            className="button"
            sx={eventsStyles.button}
          >
            Explore Event
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </LinkButton>
        </section>
      </Box>
    </Card>
  )
}

class EventsPage extends React.Component {
  render() {
    const { data } = this.props
    const events = data.allStrapiEvents.edges.map(edge => {
      const props = {
        id: edge.node.id,
        title: edge.node.title,
        description: edge.node.description,
        date: edge.node.date,
        time: edge.node.time,
        location: edge.node.location,
        url: edge.node.url,
        image: edge.node.image,
      }
      return <EventCard key={props.id} {...props} />
    })
    return (
      <Layout>
        <SEO
          title={"Jamstack Events and Conferences • Stackrole"}
          description={
            "Join and Explore the world of Jamstack with these amazing Conferences, Events, Webinars and Meetups near you."
          }
        />
        <section sx={eventsStyles.eventWrapper}>
          <div sx={eventsStyles.eventContent}>
            <div sx={eventsStyles.event}>
              <h1 sx={eventsStyles.eventheading}>
                Jamstack Events {new Date().getFullYear()}
              </h1>
              <p sx={eventsStyles.eventparagraph}>
                Join and Explore the world of Jamstack with these amazing
                Conferences, Events, Webinars and Meetups near you.
              </p>
            </div>
            <div sx={eventsStyles.eventContainer}>{events}</div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default EventsPage
